/* Circular Feedback Widget */
.feedback-widget {
    position: fixed;
    top: 50%; /* Center vertically */
    right: 20px; /* Position near the right edge */
    transform: translateY(-50%); /* Center alignment adjustment */
    z-index: 1000;
  }
  
  .feedback-widget button {
    background-color: #ff4d4d; /* Red background */
    color: white;
    border: none;
    border-radius: 50%; /* Makes it circular */
    width: 60px;
    height: 60px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .feedback-widget button:hover {
    background-color: #cc0000;
    transform: scale(1.1); /* Slight enlarge on hover */
  }
  
  /* Overlay for Feedback Form */
  .feedback-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }
  
  .feedback-form {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
  }
  
  .feedback-form h3 {
    margin-top: 0;
  }
  
  .feedback-form label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
  }
  
  .feedback-form textarea,
  .feedback-form select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .feedback-form button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
  }
  
  .feedback-form button:hover {
    background-color: #0056b3;
  }
  
  .feedback-form .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .success-message {
    color: green;
    font-weight: bold;
    margin-top: 10px;
  }
  