/* src/styles/DashboardOverview.css */

.dashboard-overview {
  padding: 24px;
  max-width: 1400px;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

/* Stats Grid */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
}

.stat-card {
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.stat-card h3 {
  font-size: 16px;
  color: #4a5568;
  margin-bottom: 12px;
  font-weight: 500;
}

.stat-value {
  font-size: 28px;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
}

/* Today's Bookings */
.todays-bookings {
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.todays-bookings h2 {
  font-size: 24px;
  color: #2d3748;
  margin-bottom: 24px;
  font-weight: 600;
}

.bookings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.booking-card {
  background: #f8fafc;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.booking-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.booking-header h3 {
  font-size: 18px;
  color: #2e384a;
  margin: 0;
  font-weight: 500;
}

.booking-status {
  padding: 4px 12px;
  border-radius: 999px;
  font-size: 14px;
  font-weight: 500;
  color: white;
}

.booking-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.booking-details p {
  margin: 0;
  font-size: 14px;
  color: #4a5568;
}

.booking-details strong {
  color: #2d3748;
  font-weight: 500;
}

.no-bookings {
  text-align: center;
  color: #4a5568;
  font-size: 16px;
  padding: 32px;
}

/* Loading and Error States */
.dashboard-loading,
.dashboard-error {
  text-align: center;
  padding: 48px;
  font-size: 16px;
  color: #4a5568;
}

.dashboard-error {
  color: #e53e3e;
}

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard-overview {
    padding: 16px;
  }

  .stats-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;
  }

  .stat-card {
    padding: 16px;
  }

  .stat-value {
    font-size: 24px;
  }

  .bookings-grid {
    grid-template-columns: 1fr;
  }
}
  