/* SignupPage.css */
.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f6f9;
    padding: 20px;
  }
  
  .signup-wrapper {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
    width: 100%;
    max-width: 450px;
    padding: 40px;
  }
  
  .signup-title {
    text-align: center;
    color: #1a1a1a;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
  }
  
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-weight: 500;
  }
  
  .form-input {
    width: 100%;
    padding: 12px 15px;
    border: 1.5px solid #e0e0e0;
    border-radius: 8px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  .form-input:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  }
  
  .signup-button {
    width: 100%;
    padding: 14px;
    background-color: #3b82f6;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .signup-button:hover:not(:disabled) {
    background-color: #2563eb;
  }
  
  .signup-button:disabled {
    background-color: #93c5fd;
    cursor: not-allowed;
  }
  
  .error-message {
    color: #ef4444;
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px;
  }
  
  .signup-footer {
    text-align: center;
    margin-top: 20px;
    color: #666;
  }
  
  .login-link {
    color: #3b82f6;
    text-decoration: none;
    font-weight: 600;
  }
  
  .login-link:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 480px) {
    .signup-wrapper {
      padding: 30px 20px;
      margin: 0 10px;
    }
  }
  .phone-number-container {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .country-code-dropdown {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
    font-size: 16px;
    flex: 0 0 35%;
  }
  
  .phone-number-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  