/* src/styles/index.css */

/* Reset some default styling */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Root variables for easy theming */
:root {
  --primary-color: #3b3c3c;
  --secondary-color: #333;
  --background-color: #f4f4f9;
  --text-color: #333;
  --font-family: Arial, sans-serif;
}

/* Global styles */
body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  line-height: 1.6;
}

/* Basic layout styling */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
}

/* Buttons */
button {
  background-color: var(--primary-color);
  color: white;
  padding: 0.6rem 1.2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: darken(var(--primary-color), 10%);
}

/* Links */
a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Navbar styling */
.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--secondary-color);
  color: white;
  padding: 1rem;
}

.navbar a {
  color: white;
  padding: 0.5rem;
}

.navbar a:hover {
  background-color: var(--primary-color);
  color: white;
  border-radius: 4px;
}

/* Card styling */
.card {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin: 1rem 0;
}

/* Calendar styles */
.calendar {
  display: flex;
  flex-direction: column;
}

.calendar .calendar-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.calendar .day-view, .calendar .week-view {
  display: flex;
  flex-direction: column;
}

.calendar-event {
  background-color: var(--primary-color);
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  margin: 0.5rem 0;
}

/* Form inputs */
input[type="text"], input[type="email"], input[type="password"] {
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0 1rem 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

/* Footer styles */
.footer {
  background-color: var(--secondary-color);
  color: white;
  text-align: center;
  padding: 1rem;
  margin-top: 2rem;
}

.footer p {
  margin: 0;
}