/* src/styles/Navbar.css */

/* Navbar Styling */
.navbar {
  display: flex;
  justify-content: space-between; /* Space between left and right sections */
  align-items: center; /* Vertically center items */
  padding: 0.8rem 2rem; /* Reduced padding for thinner navbar */
  background-color: #1e1e2d; /* Dark background */
  color: #fff; /* White text */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

/* Navbar Links Container */
.navbar-links {
  display: flex;
  justify-content: center; /* Center links horizontally */
  gap: 1rem; /* Space between links */
  flex-grow: 1; /* Allow links to take up remaining space */
}

.navbar-link {
  text-decoration: none;
  color: #ffffff; /* White text */
  font-weight: 500;
  font-size: 1rem;
  transition: color 0.3s ease;
  padding: 0.3rem 0.8rem; /* Adjusted padding for compact spacing */
}

.navbar-link:hover {
  color: #3b82f6; /* Blue hover effect */
  text-decoration: underline;
}

/* Logout Button */
.logout-button {
  background-color: #3b82f6; /* Blue button */
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 0.5rem 1rem; /* Adjusted padding */
  border-radius: 20px;
  font-size: 0.9rem;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  max-width: 150px; /* Set a maximum width */
  width: 100%; /* Make it responsive */
}

.logout-button:hover {
  background-color: #2563eb; /* Darker blue on hover */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 1rem;
  }

  .navbar-links {
    flex-direction: column;
    gap: 0.5rem;
  }

  .logout-button {
    margin-top: 0.5rem;
    width: 100%;
    max-width: 150px;
  }
}
