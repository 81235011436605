/* src/styles/Settings.css */

/* Base Page Styling */
.settings-page {
  max-width: 800px; /* Slightly wider for better layout */
  margin: 40px auto; /* Centered page */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #ffffff; /* White background */
  border: 1px solid #e0e0e0; /* Light gray border */
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.8rem;
  color: #333333; /* Dark gray for headings */
}

/* Success and Error Messages */
.success-message {
  color: #28a745; /* Green */
  text-align: center;
  margin-bottom: 10px;
}

.error-message {
  color: #dc3545; /* Red */
  text-align: center;
  margin-bottom: 10px;
}

/* Business Address Styling */
.business-address-container {
  background-color: #f9f9f9; /* Light gray background */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.business-address-container input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1rem;
}

.business-address-container .save-button,
.business-address-container .cancel-button {
  margin-top: 15px;
  padding: 8px 12px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.business-address-container .save-button {
  background-color: #007bff; /* Blue */
  color: white;
}

.business-address-container .cancel-button {
  background-color: #ccc;
  color: #333;
}

.business-address-container .save-button:hover {
  background-color: #0056b3;
}

.business-address-container .cancel-button:hover {
  background-color: #b3b3b3;
}

/* Business Hours Styling */
.business-hours-container {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9f9f9;
}

.business-hours-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

.business-hours-row:last-child {
  border-bottom: none;
}

.day-toggle label {
  font-weight: 600;
  color: #333;
}

.time-select select {
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1rem;
  background-color: #fff;
  color: #333;
  margin-left: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.3s;
  border-radius: 10px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: 0.3s;
}

input:checked + .slider {
  background-color: #007bff; /* Blue for active switch */
}

input:checked + .slider:before {
  transform: translateX(14px);
}

/* Save Button */
.save-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #0056b3;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .business-hours-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .day-toggle,
  .time-select {
    width: 100%;
  }

  .business-address-container input,
  .time-select select {
    width: 100%;
  }
}
