/* src/styles/Bookings.css */

/* General Page Styling */
.bookings-page {
  padding: 32px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff; /* White background */
  color: #333333; /* Black text */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.bookings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.bookings-header h2 {
  font-size: 28px;
  font-weight: 600;
  color: #1f2937; /* Dark gray */
}

/* Filter Buttons */
.filter-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.filter-buttons button {
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: #ffffff;
  color: #007bff; /* Blue accent */
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-buttons button:hover {
  background: #e7f1ff; /* Light blue hover */
  border-color: #007bff;
}

.filter-buttons button.active {
  background: #007bff;
  color: #ffffff; /* White text for active filter */
  border-color: #007bff;
}

/* Bookings Grid */
.bookings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
}

.booking-card {
  background: #ffffff; /* White cards */
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.booking-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
}

/* Booking Header */
.booking-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.booking-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #1f2937;
}

.booking-status {
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff; /* White text */
}

/* Booking Status Colors */
.booking-status.pending {
  background-color: #f59e0b; /* Yellow for pending */
}

.booking-status.approved {
  background-color: #10b981; /* Green for approved */
}

.booking-status.completed {
  background-color: #3b82f6; /* Blue for completed */
}

.booking-status.cancelled {
  background-color: #ef4444; /* Red for cancelled */
}

/* Booking Details */
.booking-details {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 14px;
  color: #4b5563;
}

.booking-details strong {
  color: #1f2937;
  font-weight: 600;
}

/* Action Buttons */
.booking-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 16px;
}

.approve-button,
.decline-button {
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.approve-button {
  background-color: #10b981; /* Green */
  color: #ffffff;
}

.decline-button {
  background-color: #ef4444; /* Red */
  color: #ffffff;
}

.approve-button:hover {
  background-color: #059669;
}

.decline-button:hover {
  background-color: #dc2626;
}

/* Loading and Error Messages */
.bookings-loading {
  text-align: center;
  font-size: 1.2rem;
  color: #007bff;
}

.error-message {
  background: #fee2e2;
  color: #dc2626;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 16px;
  font-size: 14px;
  text-align: center;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .bookings-page {
    padding: 16px;
  }

  .bookings-grid {
    grid-template-columns: 1fr;
  }

  .filter-buttons {
    gap: 8px;
  }

  .filter-buttons button {
    padding: 6px 10px;
    font-size: 12px;
  }
}
