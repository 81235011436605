/* Login.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

:root {
  --primary-blue: #3B82F6;
  --dark-blue: #1E40AF;
  --light-gray: #F3F4F6;
  --text-dark: #1F2937;
  --text-light: #6B7280;
}

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--light-gray);
  font-family: 'Inter', sans-serif;
}

.login-container {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  padding: 40px;
  width: 100%;
  max-width: 450px;
}

.login-header {
  text-align: center;
  margin-bottom: 30px;
}

.login-header h2 {
  color: var(--text-dark);
  font-size: 24px;
  margin-bottom: 10px;
}

.login-header p {
  color: var(--text-light);
  font-size: 14px;
}

.error-banner {
  background-color: #FEE2E2;
  color: #991B1B;
  padding: 10px 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: var(--text-dark);
  font-weight: 500;
}

.form-input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid var(--light-gray);
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.form-input:focus {
  outline: none;
  border-color: var(--primary-blue);
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.forgot-password {
  position: absolute;
  right: 0;
  top: -25px;
  color: var(--primary-blue);
  font-size: 14px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.forgot-password:hover {
  color: var(--dark-blue);
}

.login-button {
  background-color: var(--primary-blue);
  color: white;
  border: none;
  padding: 14px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: var(--dark-blue);
}

.signup-link {
  text-align: center;
  margin-top: 20px;
  color: var(--text-light);
}

.signup-link a {
  color: var(--primary-blue);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.signup-link a:hover {
  color: var(--dark-blue);
}