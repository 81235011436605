/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
  overflow-y: auto; /* Ensures scrollability */
}

.services-page {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: auto; /* Fix for scrollability */
}

/* Header Styles */
h2 {
  margin-bottom: 15px;
  font-size: 1.8rem;
  color: #222;
  border-bottom: 2px solid #0078ff;
  display: inline-block;
  padding-bottom: 5px;
}

h4 {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #0078ff;
}

/* Form Inputs */
input[type="text"],
input[type="number"],
textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

button {
  padding: 10px 15px; /* Standardized button size */
  margin: 10px 5px 0 0;
  background-color: #0078ff;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: auto; /* Makes button width dynamic */
  min-width: 100px; /* Ensures consistent button sizes */
}

button:hover {
  background-color: #005bb5;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin: 5px 0;
}

/* Toggle Switch */
.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the toggle */
  margin: 20px 0;
}

.toggle-container span {
  font-size: 1rem;
  margin: 0 10px;
}

.toggle-container span.active {
  font-weight: bold;
  color: #0078ff;
}

.toggle-switch {
  width: 60px;
  height: 30px;
  background: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  transition: background 0.3s;
}

.toggle-switch.rate {
  background: #0078ff;
}

.toggle-circle {
  width: 28px;
  height: 28px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: left 0.3s;
}

.toggle-switch.rate .toggle-circle {
  left: 31px;
}

/* Section Styling */
.specific-question-config,
.services-section,
.distance-pricing {
  margin-bottom: 30px;
}

.dropdown-config {
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 5px;
}

.dropdown-option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropdown-option input[type="text"],
.dropdown-option input[type="number"] {
  flex: 1;
}

.dropdown-option button {
  background-color: #ff4d4f;
  color: white;
  width: 80px; /* Reduced width for Delete button */
  font-size: 0.9rem;
}

.dropdown-option button:hover {
  background-color: #d9363e;
}

/* Service Section */
.services-section ul {
  list-style-type: none;
  padding: 0;
}

.services-section li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f4f4f4;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 5px 0;
}

.services-section li span {
  flex: 1;
}

.services-section li button {
  padding: 5px 10px;
  margin: 0 5px;
  font-size: 0.9rem;
  width: 80px; /* Reduced width for Edit/Delete buttons */
}

.services-section li button:first-child {
  background-color: #ffc107;
}

.services-section li button:first-child:hover {
  background-color: #e0a800;
}

.services-section li button:last-child {
  background-color: #ff4d4f;
}

.services-section li button:last-child:hover {
  background-color: #d9363e;
}

/* Distance Pricing */
.distance-pricing label {
  display: block;
  margin-bottom: 10px;
  font-size: 1rem;
}
.script-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.script-textbox {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.copy-button {
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  background-color: #4a90e2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background-color: #3a7bd5;
}

.copy-button:active {
  background-color: #2c6ba1;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}
