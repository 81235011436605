/* Step2.css */
.onboarding-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.onboarding-container h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.onboarding-container h3 {
  font-size: 18px;
  font-weight: normal;
  color: #555;
  margin-bottom: 20px;
}

.niche-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 15px;
}

.niche-box {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
}

.niche-box.selected {
  background-color: #007bff;
  color: white;
  transform: scale(1.05);
}

.niche-box:hover {
  background-color: #e9ecef;
}

.niche-icon {
  font-size: 30px;
  margin-bottom: 10px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.other-niche-input {
  margin-top: 20px;
}

.other-niche-input label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.other-niche-input input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.2s;
}

.other-niche-input input:focus {
  border-color: #007bff;
  outline: none;
}
