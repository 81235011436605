/* General Body Reset */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevent scrollbars unless needed */
}

/* Calendar Container */
.calendar-page {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width */
  box-sizing: border-box;
}

/* Full-Height Calendar */
.fc {
  height: 100%; /* Makes the calendar fill its container */
  width: 100%; /* Full width of the page */
}

/* Calendar Header */
.fc-col-header {
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.fc-col-header-cell {
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
  color: #333;
  border-right: 1px solid #ddd;
}

.fc-col-header-cell:last-child {
  border-right: none;
}

/* Time Slots */
.fc-timegrid-slot {
  height: 90px; /* Increase height for better visibility */
  border-bottom: 1px solid #ddd;
}

.fc-timegrid-slot-label {
  font-size: 12px;
  color: #666;
  text-align: right;
  padding-right: 8px;
}

/* Half-Hour Labels */
.slot-half-hour-label {
  position: absolute;
  font-size: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  left: -5px;
}

/* Booked Events */
.fc-event {
  background-color: #3288f2; /* Green for booked events */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  padding: 2px 5px;
  cursor: pointer;
  transition: transform 0.2s;
}

.fc-event:hover {
  transform: scale(1.05);
}

/* Closed Hours */
.closed-hours {
  background-color: #f5f5f5;
  opacity: 0.6;
  pointer-events: none; /* Prevent interactions */
}

/* Dragging Styles */
.dragging {
  opacity: 0.8;
  z-index: 1000;
  transform: scale(1.1);
  transition: none;
}

/* Grid Lines */
.fc-timegrid-line {
  border-top: 1px solid #ddd;
}

.fc-timegrid-line:last-child {
  border-bottom: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .fc-col-header-cell {
    font-size: 10px;
    padding: 5px 0;
  }

  .fc-timegrid-slot-label {
    font-size: 10px;
  }

  .fc-event {
    font-size: 10px;
    padding: 1px 3px;
  }
}

@media (max-width: 600px) {
  .fc-timegrid-slot {
    height: 60px; /* Reduce slot height */
  }

  .fc-event {
    font-size: 9px;
    padding: 2px 3px;
  }
}